/**
 * loading
 */
$(function () {
    $('.loader').fadeOut('slow', function () {
        $(this).remove();
    });

    var goTop = function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 0) {
                $('.go-top').addClass('show');
            } else {
                $('.go-top').removeClass('show');
            }
        });

        $('.go-top').on('click', function () {
            $("html, body").animate({
                scrollTop: 0
            }, 1000);
            return false;
        });

        $('.weixin').hover(function () {
            $('.weixin_ma').fadeToggle(500);
        })

        $('.weibo').hover(function () {
            $('.weibo_ma').fadeToggle(500);
        })
    };

    goTop();

    if ($('.flexslider').length) {
        $('.flexslider').flexslider({
            animation: "fade"
        });
    }

    $(".box").superSlider({
        prevBtn: ".prev", //左按钮
        nextBtn: ".next", //右按钮
        listCont: "#roll", //滚动列表外层
        scrollWhere: "next", //自动滚动方向next
        delayTime: 3000, //自动轮播时间间隔
        speed: 300, //滚动速度
        amount: 1, //单次滚动数量
        showNum: 4, //显示数量
        autoPlay: true //自动播放
    });
});